import SideBarMenuItem from '../components/sidebar/domain/SideBarMenuItem';
import CoreRouteTypes from '@/router/types';
import { getTranslation } from '@/services/TranslationService';

class CoreSideBarManager {
    private _menuItems: SideBarMenuItem[] = [];

    constructor() {
        this.updateMenuItems();
    }

    public updateMenuItems() {
        this._menuItems = [
            {
                id: 1,
                title: getTranslation('core.common.general').toUpperCase(),
                type: SideBarMenuItem.TYPE.HEADER,
            }, {
                id: 2,
                title: getTranslation('core.common.home').toUpperCase(),
                icon: 'home',
                routeName: CoreRouteTypes.HOME,
                type: SideBarMenuItem.TYPE.BUTTON,
                show: true,
            },
        ];
    }

    get menuItems(): SideBarMenuItem[] {
        return this._menuItems;
    }
}

const sideBarNavItemManager = new CoreSideBarManager();
export default sideBarNavItemManager;
