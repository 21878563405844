

import { defineComponent, reactive } from 'vue';
import coreSideBarManager from '@/router/CoreSideBarManager';

export default defineComponent({
    name: 'core',
    components: {},
    props: {},
    setup() {
        const sidebarManager = reactive(coreSideBarManager);

        return {
            sidebarManager,
        };
    },
});

